/**
 Формат работы
 */
@import "../media";


.about-formats {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 160px;
  @include max800 {
    margin-bottom: 60px;
    margin-top: 70px;
  }
}

.about-formats .h3 {
  letter-spacing: .02em;
  margin-bottom: 45px;
  @include max576 {
    margin-bottom: 7px;
  }
}

.about-formats__text {
  padding-top: 12px;
  font-size: 16px;
  @include max576 {
    font-size: 14px;
  }
}


@media (max-width: 767px) {
  .about-formats {
    padding-left: 15px;
    padding-right: 15px
  }

  .about-formats h3 {
    margin-bottom: 25px
  }
}


.formats {
  margin-top: 75px;
  margin-bottom: 80px;
  position: relative
}

.formats .col-xs-12 {
  margin-bottom: 50px
}



.format-item {
  background: #f6f6f9;
  padding: 51px 38px;
  height: 100%;
  position: relative;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  overflow: hidden;

  @include max576 {
    max-width: unset;
    width: 86%;
    margin-bottom: 30px;
    height: 286px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 20px;
  }

  &:hover {
    -webkit-box-shadow: 42px 34px 50px rgba(57, 60, 67, .5);
    box-shadow: 42px 34px 50px rgba(57, 60, 67, .5);
    background-color: #252733;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);

    @include max576 {
      transform: scale(1.05);
      padding: 38px;
      height: 286px;
    }

    &::before {
      top: -20px;
      bottom: -20px;
      left: -20px;
      right: -20px;
      opacity: 1
    }

    .format-item__desc p {
      @include max576 {
        font-size: 14px;
      }
    }
  }


  &::before {
    display: block;
    background-color: #252733;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none
  }

  &__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    margin-top: 55px;
    margin-bottom: 5px;
    -webkit-transition: margin .3s;
    -o-transition: margin .3s;
    transition: margin .3s;
    min-height: 3em;
    @include max576 {
      font-size:20px;
    }
  }
  &__desc {
    min-height: 160px;
    font-size: 16px;
    line-height: 150%;
    color: rgba(255, 255, 255, .8);
    opacity: 0;
    position: absolute !important;
    left: 35px;
    right: 35px;
    z-index: 111;
    @include max576 {
      font-size:14px;
    }
    p {
      font-size: inherit;
      color: #252733;
    }
  }

}






.format-item__icon {
  margin-left: 20px;
  opacity: 0
}

.format-item__icon-big {
  height: 96px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-left: 12px
}




.format-item:hover .format-item__title, .owl-item:not(.active) + .owl-item.active .format-item .format-item__title {
  //color: #fff;
  margin-top: 0
}

.format-item:hover .format-item__desc, .owl-item:not(.active) + .owl-item.active .format-item .format-item__desc {
  opacity: 1
}

.format-item:hover .format-item__head, .owl-item:not(.active) + .owl-item.active .format-item .format-item__head {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row
}

.format-item:hover .format-item__icon, .owl-item:not(.active) + .owl-item.active .format-item .format-item__icon {
  opacity: 1
}

.format-item:hover .format-item__icon-big, .owl-item:not(.active) + .owl-item.active .format-item .format-item__icon-big {
  opacity: 0
}


.about-formats .format-item {
  background: #252733;
  border-radius: 25px;
  color: #fff;
}

.about-formats .format-item::before, .about-formats .format-item:hover, .about-formats .owl-item:not(.active) + .owl-item.active .format-item {
  background-color: #fff;
  color: #252733
}

.page_main .about-formats .format-item:hover .format-item__title, .page_main .about-formats .format-item__desc, .page_main .about-formats .owl-item:not(.active) + .owl-item.active .format-item .format-item__title {
  color: #252733
}
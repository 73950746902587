@import "../media";

/**
 Пресса
 */

.press {
  background: #F9FAFC;
  text-align: center;
  opacity: .5;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  border-radius: 20px;
  margin: 15px 0 30px;
  overflow: hidden;
  position: relative;
  width: 320px;
  height: 140px;
  @include max800 {
    margin: 0 auto;
  }

  &::before {

  }

  &:hover {
    opacity: 1;
    -webkit-box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
    box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}


.owl-press {
  margin-left: 20px;
  @include max800 {
    margin-left: 0;
  }

  .owl-dots, .owl-dots.disabled {
    @include max576 {
      margin-top: 20px;
    }
  }

    .owl-nav {
      position: unset;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      height: 0;
      top: 55%;
      transition: all 0.3s ease-out;

      &.animate {
        transition: all 0.3s ease-out;
        top: 50%;
        opacity: 1;
      }

      .owl-prev, .owl-next {
        top: 35%;
        position: absolute;

      }

      .owl-prev {
        left: -1.5%;
      }

      .owl-next {
        right: 0.5%;
        @include max1050 {
          right: 3.5%;
        }
        @include max800 {
          right: -1.5%;
        }
      }
    }
  }

@import "../media";

@include max1670 {




  .mainpage-top__text {
    padding-left: 0;
  }

  .mainpage-top__logo-container {
    padding-right: 0;
  }
}

/**
  Первая секция
 */
.mainpage-top {
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-color: #ccc;
  background-size: cover;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  min-height: 600px;
  padding-top: 120px;
  overflow: hidden;

  @include max1670 {
    height: calc(var(--vh, 1vh) * 118);
  }
  @include max576 {
    padding-top: 0px;
    height: 100vh;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -o-linear-gradient(203.89deg, #FFFFFF 47.42%, rgba(255, 255, 255, 0.5) 68.34%, rgba(255, 255, 255, 0) 76.05%);
    background: linear-gradient(246.11deg, #FFFFFF 47.42%, rgba(255, 255, 255, 0.5) 68.34%, rgba(255, 255, 255, 0) 76.05%);
    opacity: .9;
    pointer-events: none
  }

  .page__text-bg {
    top: 105px
  }

  .container {
    position: relative;
    z-index: 1;
    height: 100%;

    @include max1670 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding-bottom: 120px;
    }
    @include  max576 {
      padding: 0px 18px;
    }
  }

  &__title {
    font-weight: 800;
    font-size: 96px;
    line-height: 130%;
    margin-bottom: 85px
  }

  &__subtitle {
    font-size: 30px;
    line-height: 130%;
    text-transform: uppercase;
    font-weight: 600;
    text-align: right;
    margin-bottom: 71px;
    @include max1670 {

      margin-bottom: 40px;

    }
    @include max576 {
      font-size: 20px;

    }
  }

  &__logo-container {
    padding-right: 110px;
    @include max1050 {
      padding: 0;
    }
  }
  &__logo-wrap {
    margin: 14vh auto 0;
    max-width: 800px;
    padding: 0 10px;
    @include max1670 {
      max-width: 600px;
    }
    @include max576 {
      margin-top: 0px;
    }
  }

  &__logo {
    margin-bottom: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @include max1670 {
      margin-bottom: 20px;
      margin-left: -25px;
      margin-right: -25px;
    }
  }

  &__logo-text {
    position: relative;
    margin: 60px 0 40px;
    height: 62px;
    margin-top: 86px;
    margin-bottom: 44px;
    @include max1670 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    @include max576 {
    }
  }


  &__logo-text-arrow {
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    font-size: 18px;
    font-weight: normal;
    @include max576 {
      font-size: 14px;
      width: 300px;
      margin-top: -16px;
      svg {
        margin-top: -1px;
        width: 100% !important;
      }
    }
  }


  &__logo-text-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #374859;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 62px;
    opacity: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    pointer-events: none;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);

    &.active {
      opacity: 1;
      pointer-events: auto;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }

    .accent-color {
      font-weight: 600;
      font-size: 30px;
      line-height: 130%;
      text-transform: uppercase;
      margin-right: 178px;
      @include max1670 {
        font-size: 40px;
      }
      @include max576 {
        font-size: 20px;
        margin-right: 40px;
      }
    }

    svg {
      display: block;
      margin-top: 5px;
    }
  }


  &__text {
    font-size: 18px;
    line-height: 130%;
    position: relative;
    margin-bottom: 90px;
    font-weight: 600;
    text-transform: lowercase;
    color: #252733;
    font-weight: normal;
    @include max576 {
      font-size: 16px;
      margin-top: -27px;
    }
  }

  &__letter {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    margin: 0 10px;
    @include max1670 {
      -webkit-box-flex: 1;
      -ms-flex: auto;
      flex: auto;
      -ms-flex-negative: 1;
      flex-shrink: 1;
      margin: 0 25px;
    }
    @include max576 {
      margin: 0 8px;
    }

    svg {
      //stroke: #252733;
      cursor: pointer;
      height: auto;
      width: 100%;
      fill: transparent;
      -webkit-transition: .5s;
      -o-transition: .5s;
      transition: .5s;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }

    svg path {
      -webkit-transition: .5s;
      -o-transition: .5s;
      transition: .5s;
    }

    &:hover svg,
    &.active svg {
      stroke: none;
    }

    &.letter-c svg {
      max-width: 161px; /*height: 158px;*/
    }

    &.letter-c:hover svg,
    &.letter-c.active svg {
      fill: #f48f6f;
      stroke: #f48f6f;
    }

    &.letter-m svg {
      max-width: 199px; /*height: 151px;*/
    }

    &.letter-m:hover svg,
    &.letter-m.active svg,
    &.letter-i:hover svg,
    &.letter-i.active svg {
      fill: #385C8B !important;
      stroke: #385C8B !important;
    }

    &.letter-p svg {
      max-width: 152px; /*height: 151px;*/
    }

    &.letter-i svg {
      max-width: 46px;
    }

    &.letter-p:hover svg,
    &.letter-p.active svg {
      fill: #5D8D94;
      stroke: #5D8D94;
    }


  }

  &__controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .btn-primary {
      color: #fff !important;
      margin-right: 160px;
      margin-top: 5px;

      span {
        color: #fff !important;
        background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, #385C8B 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #385C8B), to(#385C8B));
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, #385C8B 100%)
      }

      .svg-icon {
        margin-left: 14px
      }
    }

    .btn-play {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      letter-spacing: .02em;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;

      &:hover::before {
        background-color: #32527d
      }

      &:active::before, &:focus::before {
        background-color: #32527d
      }

      &::before {
        content: '';
        display: block;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 78px;
        height: 78px;
        margin: 0 25px 0 0;
        border-radius: 50%;
        background: #385C8B url(../images/icon-play.svg) no-repeat 50% 50%;
        -webkit-box-shadow: 0 26px 50px rgba(56, 92, 139, .6);
        box-shadow: 0 26px 50px rgba(56, 92, 139, .6)
      }
    }
  }

  &__social {
    position: absolute;
    bottom: 70px;
    left: 50%;
    margin-left: -43.2vw;
    width: 88vw;
    font-size: 26px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    a {
      color: #252733;
      margin-right: 75px;
      text-shadow: 0 0 10px rgba(255, 255, 255, .8);
      @include max576  {
        margin-right: 25px;
      }

      .instagram {
        width: 27px !important;
        height: 28px !important;
        position: relative;
        top: 2px;
      }

      svg {
        @include max576 {
          width: 20px !important;
        }
      }
    }
    .btn-video {
      -webkit-box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
      box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
    }
    .btn-play {
      border-radius: 50%;

      line-height: 0;
      display: block;
      padding: 1px;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      position: relative;
      top: -1px;

      @include max576 {
        width: 50px;
        margin-top: -6px;
       svg {
         width: 100% !important;

       }
      }
    }
  }

  &__menu {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    max-width: 360px;
    z-index: 3;

    &-item {
      border: 1px solid #bbb8b8;
      padding: 22px 42px;
      position: relative;
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -50%;
        width: 200%;
        height: 100%;
        background: -o-linear-gradient(right, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), rgba(255, 255, 255, 0)) no-repeat -25em 0;
        background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), color-stop(rgba(255, 255, 255, .8)), to(rgba(255, 255, 255, 0))) no-repeat -25em 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), rgba(255, 255, 255, 0)) no-repeat -25em 0;
        background-size: 25em 100%;
        -webkit-transform: skewX(-50deg);
        -ms-transform: skewX(-50deg);
        transform: skewX(-50deg);
        pointer-events: none
      }
      &:hover::after {
        -webkit-transition: 1.5s linear;
        -o-transition: 1.5s linear;
        transition: 1.5s linear;
        background-position: 530% 0
      }
    }

    &-icon {
      margin-bottom: 25px
    }

    &-text {
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      max-width: 250px;
      a {
        color: inherit;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0
        }
      }
    }
  }

}


@-moz-document url-prefix() {
  .mainpage-top {
    left: 50%;
    width: 118vw;
    margin-left: -59vw;
    background-position: 50% 50%;
  }
}



.mainpage-top__down {
  position: absolute;
  bottom: 30px;
  left: -13%;
  width: 117px;
  height: 117px;
  pointer-events: none
}

.mainpage-top__down a {
  pointer-events: auto;
  display: block;
  position: absolute;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #fff;
  padding-top: 8px
}

.mainpage-top__down .svg-icon {
  width: 117px
}







.mainpage-top__logo {
  margin-left: -10px;
  margin-right: -10px;
}

.mainpage-top__logo.init .mainpage-top__letter svg {
  -webkit-animation: mainpage-top-letter 1s linear forwards;
  animation: mainpage-top-letter 1s linear forwards;
}

@-webkit-keyframes mainpage-top-letter {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes mainpage-top-letter {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.mainpage-top__logo.init .mainpage-top__letter.letter-c svg {
  fill: #f48f6f;
  stroke: #f48f6f;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.mainpage-top__logo.init .mainpage-top__letter.letter-m svg {
  fill: #385C8B;
  stroke: #385C8B;
  -webkit-transition-delay: .6s;
  -o-transition-delay: .6s;
  transition-delay: .6s;
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
}

.mainpage-top__logo.init .mainpage-top__letter.letter-p svg {
  fill: #5D8D94;
  stroke: #5D8D94;
  -webkit-transition-delay: .9s;
  -o-transition-delay: .9s;
  transition-delay: .9s;
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

.mainpage-top__logo.init .mainpage-top__letter.letter-i svg {
  fill: #385C8B;
  stroke: #385C8B;
  -webkit-transition-delay: 1.2s;
  -o-transition-delay: 1.2s;
  transition-delay: 1.2s;
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
}


/**
  Темы
 */


.mpage-themes {
  margin-bottom: 160px;
  @include max1050 {
    margin-top: 160px;
  }
  @include max800 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .btn-info {
    margin-top: 65px;
    width: 146px;
    text-align: left;
    padding-left: 0;
    @include max576 {
      margin-top: 13px;
    }

    span {
      width: 100%;
      text-align: left;
    }
  }


  p {
    margin-top: 5px;
    font-size: 16px;
    line-height: 140%;

  }

  &__menu {
    margin-bottom: 84px;
    margin-top: 50px;
    @include max576 {
      margin-top: 22px;
      margin-bottom: 41px;
    }
  }

  &__menu-item {
    margin-bottom: 10px;
    max-width: 220px;
    display: inline-block;
    margin-right: 50px;
    font-size: 16px;
    line-height: 130%;
    @include max576 {
      font-size: 14px;
      margin-right: 12px;
    }
  }

  &__menu-link {
    display: block;

    position: relative;
    transition: all 0.1s linear;


    span {
      position: relative;
    }

    &::before {
      content: "";
      display: block;
      background: #f48f6f;
      width: 0%;
      height: 9px;
      position: absolute;
      bottom: 2px;
      z-index: -1;
      opacity: 0.5;
      transition: all 0.1s linear;
    }

    &:hover, &.active {
      font-weight: bold;
      transition: all 0.1s linear;

      &::before {
        transition: all 0.1s linear;
        width: 122%;
      }
    }


  }


  //.owl-nav {
  //  display: none !important;
  //}
  .owl-prev, .owl-next {
    top: 39%;
  }

  .owl-prev {
    left: -1.5%;
    @include max800 {
      left: -20px;
    }
  }

  .owl-next {
    right: 18.5% !important;
    @include max1050 {
      right: 22.5% !important;
    }
    @include max800 {
      right: 28.5% !important;
    }
  }

  .owl-dots {
    bottom: 2px !important;
    width: 670px;
    height: 2px;
    background: #D4D4D7;
    display: flex;
    margin-left: 25%;
    margin-top: 31px;
    @include max1050 {
      margin-left: 16%;
    }
    @include max800 {
      margin-left: 7%;
    }
    @include max576 {
      margin-left: 0;
      background: none;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &.disabled {
      width: 670px;
      height: 2px;
      background: #D4D4D7;
      display: flex;
      margin-left: 25%;
      margin-top: 31px;

      @include max1050 {
        margin-left: 16%;
      }
      @include max576 {
        background: none;
      }

    }

    .owl-dot {
      display: block;
      height: 2px;
      width: 100%;


      @include max576 {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #f48f6f;
        margin: 0 12px;
        transition: all 0.3s linear;
        opacity: 0.3;
      }

      &.active {
        background: #f48f6f;
        transition: all 0.3s linear;
        height: 4px;
        top: -1px;
        @include  max576 {
          width: 12px;
          height: 12px;
          opacity: 1;
        }
      }
    }
  }
}


.mpage-themes .mainpage-group__title {
  margin-top: 0;
}

.mainpage-group__title {
  margin-top: 0;
}


.mpage-themes__btn {
  margin-top: 55px;
  margin-bottom: 105px;
}

.mpage-themes__btn span {
  padding-right: 50px;
}


.mainpage-group {
  position: relative
}

.mainpage-group__subtitle {
  margin: 12px 6px 14px;
  font-size: 24px;
  position: relative
}

.mainpage-group__title {
  font-size: 40px;
  margin-bottom: 30px;
  line-height: 1.25;
  position: relative;
  color: inherit;
  font-weight: 700;
  @include  max576 {
    font-size:30px;
  }
}

.mainpage-group__title-arrow {
  margin: -15px 0 45px;
  position: relative
}

.mainpage-group__title-arrow .svg-icon {
  width: 45px
}

/**
  Кого хотите найти
 */
.who-search {

  background: url("../../images/who-search.png");
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: 56% 0%;
  padding-bottom: 72px;

  @include zoomMozillaContainerPosition;


  @include max1450 {
    background-size: 141%;
  }
  @include max1050 {
    background-size: 177%;
  }

  @include max800 {
    background-size: 242%;
  }
  @include  max576 {
    background-size: 563%;
  }

  &__header {
    font-size: 40px;
    line-height: 130%;
    color: #FFFFFF;
    font-weight: bold;
    margin-top: 86px;
    @include max576{
      font-size:30px;
    }
  }

  &__blockquote {
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    margin-top: 136px;
    width: 859px;
    padding-left: 170px;
    position: relative;

    @include max800 {
      padding-left: 83px;
      width: 100%;
    }
    @include max576 {
      padding-left: 35px;
      width: 100%;
      margin-top: 64px;
      font-size: 14px;
    }

    &:before {

      content: "";
      display: block;
      width: 98px;
      height: 95px;
      position: absolute;
      background: url(../../images/orage-quote.svg);
      background-repeat: no-repeat;
      background-size: contain;
      top: -83px;
      left: 0;
      @include max800 {
        width: 76px;
      }
      @include max576 {
        width: 44px;
        top: -42px;
      }

    }

    span {
      font-style: normal;
      display: block;
      text-align: right;
      margin-top: 53px;
    }
  }

  .btn__outline {
    box-shadow: unset;
    background: transparent;
    color: #fff;
    margin-left: 170px;
    margin-right: 20px;
    margin-top: 44px;
    transition: all 0.3s linear;
    @include max576 {
      margin: 0 auto;
      display: block;
      width: 266px;
      margin-top: 36px;
    }

    &:hover {
      background: #F48F6F;
      color: #fff;
      transition: all 0.3s linear;
    }
  }

  .btn__primary {
    margin-top: 44px;
    @include max576 {
      margin: 0 auto;
      display: block;
      width: 266px;
      margin-top: 20px;
    }
  }
}


/**
  ТОП спецы
 */

#main-top-accounts {
  position: relative;
  width: 1600px;
}

.best-spec {
  margin-top: 120px;
  position: relative;

  .container {
    position: relative;
  }

  .tabs-simple {
    margin-bottom: 48px;
    @include max576 {
      margin-bottom: 7px;
    }
  }


  &__rectangle {
    position: absolute;
    z-index: -1;
    right: -133px;
    top: 335px;
    @include max800 {
      width: 70%;
      top: 288px;
      svg {
        width: 100%;
      }
    }
    @include max576 {
      width: 115%;
      top: 35%;
    }
  }

  &__rectangle2 {
    background: #f9fafc;
    position: absolute;
    z-index: -1;
    width: 62vw;
    height: 600px;
    display: block;
    top: 143px;
    right: 85%;

    svg {
      position: absolute;
      right: 366px;
      top: 125px;
    }
  }

  &__abzac {
    @include max576 {
      font-size: 14px;
    }
  }



  .tabs a {
    margin-right: 30px;
    background: none;
    position: relative;
    display: inline-block;

    span {
      position: relative;
    }

    &:before {
      content: "";
      display: block;
      background: #f48f6f;
      width: 0%;
      height: 9px;
      position: absolute;
      bottom: 2px;
      z-index: -1;
      opacity: 0.5;
      transition: all 0.1s linear;
    }

    &:hover, &.active {
      font-weight: bold;
      transition: all 0.1s linear;

      &:before {
        width: 122%;
        transition: all 0.1s linear;
      }
    }
  }

  .tabs.active {
    font-weight: 800;
  }

  .btn-info {
    margin-top: 44px;
    padding-left: 0;
    @include max576 {
      padding-left: 0;
      margin: 0;
      display: block;
      width: 191px;
      font-size: 14px;
      margin-top: 30px;
    }
  }
}

.best-spec__desc {
  margin-top: -5px;
  margin-bottom: 60px;
}


.best-spec .spectop-card {
  margin-top: 160px;
  width: 79%;
  display: flex;
  margin-bottom: 92px;
  display: none;



  @include max800 {
    width: 100%;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  @include  max576 {
    margin-top: 30px;
    flex-wrap: wrap;
  }

  .spec-card__contacts  {
    display: none;

  }
  &__photo {
    width: 434px;
    border-radius: 31px;
    overflow: hidden;
    position: relative;


  }
  .spectop-card__video-accent {
    position: absolute;
    top: 776px;
    left: 35px;
    @include max1050 {
      left: 72px;
    }
    @include max800 {
      top: 801px;

    }
    @include max576 {
      top: 666px;
      left: 31px;

    }
  }



  &__cont {
    margin-left: 80px;
    width: 40%;
    @include max800 {
      margin-left: 29px;
    }
    @include max576 {
      width: 100%;
      margin: 0;
      margin-top: 60px;
    }
  }

  .spec-card__content {
    width: 100%;
    margin: 0;
    padding: 0;

    .spec-card__title {
      font-size: 30px;
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 30px;
    }

    .spec-card__name {
      display: block;
    }

    .spec-card__competitions {
      font-size: 12px;
      margin-top: 6px;
    }

    .spec-card__prehead:after {
      border-radius: 40px;
      margin-top: 20px;
      margin-bottom: 0;

    }

    .spec-card__info {
      margin-top: 14px;
      font-size: 14px;
    }

    .spec-card__desc {
      font-size: 14px;
    }

    .spec-card__total-item {
      font-size: 14px;
    }

    .spec-card__viewed {
      font-size: 12px;
      font-weight: bold;

      svg {
        width: 14px;
      }
    }
  }

}


/**
  CMPI личный успех
 */
.main-cmpi {
  position: relative;

  //&__rectangle1, &__rectangle2, {
  //  position: absolute;
  //  width: 350px;
  //  height: 300px;
  //  background: url(../../images/Rectangle1111.png)
  //}

  &__rectangle1 {
    position: absolute;
    z-index: -1;
    right: 20px;
    top: 393px;
    @include max800 {
      right: -93px;
    }
    @include max576 {
      top: 787px;
    }

  }
  &__rectangle2 {
    position: absolute;
    z-index: -1;
    left: 236px;
    top: 215px;
   @include max1050 {
     left: 133px;
   }
    @include max800 {
      display: none;
    }
  }

  &__big-title {
    //font-weight: bold;
    //font-size: 77px;
    //letter-spacing: 13px;
    //line-height: 67px;
    margin-bottom: 6px;
    @include max576 {
      //font-size: 50px;
    }
  }

  .btn__outline {
    margin-top: 48px;
    @include max576 {
      margin: 0 auto;
      display: block;
      width: 266px;
      margin-top: 30px;
    }
  }

  &__numblock {
    height: 245px;
    display: flex;
    background: url(../../images/main-cmpi-block.png);
    width: 1162px;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 120px;
    justify-content: space-around;
    border-radius: 40px;
    background-attachment: fixed;

    justify-content: space-around;
    background-size: 91%;
    position: relative;

    @include max1670 {
      @-moz-document url-prefix() {
        background-size: 100%;
      }
    }
    @include max1050 {
      width: 100%;
      background-size: 99%;
    }
    @include max576 {
      width: 100%;
      flex-wrap: wrap;
      height: auto;
      margin-top: 60px;
      background-attachment: unset;
      background-size: cover;
    }

    &-rectangle {
      position: absolute;
      left: 70px;
      top: -67px;
      @include max800 {
        display: none;
      }
    }


  }

  &__numblock-item {
    width: 141px;
    margin-top: 69px;

    @include max576 {
      width: 141px;
      margin-top: 69px;
      width: 45%;
      margin: 0 25%;
      margin-top: 60px;
    }
    &:last-child {
      @include max576 {
        margin-bottom: 60px;
      }
    }

    .num {
      font-weight: bold;
      font-size: 48px;
      line-height: 130%;
      position: relative;
      /* or 62px */

      text-transform: uppercase;

      color: #F48F6F;

      .plus {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 20px;
        line-height: 130%;
        text-transform: uppercase;
        color: #FFFFFF;
        opacity: 0.8;

      }

    }

    .text {
      font-weight: bold;
      font-size: 16px;
      line-height: 130%;
      text-transform: uppercase;
      color: #FFFFFF;
      opacity: 0.9;
      text-shadow: 0px 48px 120px rgba(0, 26, 73, 0.1);
    }
  }
}


/**
  Как проходит общение со спецом
 */
.how-it-works {
  position: relative;
  margin-bottom: 160px;
  @include max800 {
    margin-bottom: 60px;
  }

  &__wrap {
    display: flex;
    @include max576 {
      width: 100%;
    }

  }

  &__rectangle {
    position: absolute;
    //top: 155px;
    //right: 37px;
    top: 49px;
    right: -45px;
    z-index: -1;
    opacity: 1;
    @include max1050 {
      top: 18px;
      right: -2px;
      top: -3px;
      right: -39px;
      width: 269px !important;
    }
    @include max800 {
      //width: 218px !important;
      //top: -9px;
      //right: -33px;
      display: none;
    }

    //&.animate {
    //  visibility: visible;
    //  top: 49px;
    //  right: -45px;
    //  opacity: 1;
    //  transition: all 0.6s ease-in-out;
    //  transition-delay: 0.6s;
    //
    //  @include max1050 {
    //    top: 18px;
    //    right: -2px;
    //  }
    //  @include max800 {
    //    width: 218px !important;
    //    top: -9px;
    //    right: -33px;
    //  }
    //}
  }

  /* Убираем слайды путем нулевого транзитиона */
  .owl-carousel .animated {
    animation-duration: 0ms;
    animation-fill-mode: both;
  }
  .scroll-down {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 75px;
    height: 80px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    bottom: 0;
    transform: translateX(-50%);
    transform: scale(0.9);
    position: absolute;
    left: -18px;
    bottom: 270px;
    @include max1050 {
      display: none;
    }
  }
  .scroll-down>label {
    pointer-events: none;
    color: #252733;
    font-size: 14px;
    display: block;
  }

  .scroll-down {
    height: 100px;
  }
  .scroll-down span {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 60px;
    border: 2px solid #252733;
    border-radius: 50px;
    top: 5px;

  }
  .scroll-down span::after {
    position: absolute;
    content: "";
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 2px;
    height: 20px;
    border-radius: 2px;
    background: #252733;
    animation: animateMouseScrollBar 1.5s infinite linear;
  }
  @keyframes  animateMouseScrollBar {
    0% {
      height: 20px;
      top: 10px;
    }
    50% {
      height: 0;
      top: 30px;
    }
    50.01% {
      top: 10px;
    }
    100%{
      height: 20px;
    }
  }

  .owl-dots {
    display: block;
    //width: 100%;
    position: absolute;
    top: 9%;
    left: 0%;
    @include  max800 {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .owl-dot {
      width: 12px;
      height: 12px;
      background: #F48F6F;
      opacity: 0.3;
      transition: all 0.3s linear;
      margin: 12px 0;
      border-radius: 50%;
      @include max800 {
        margin: 0 12px;
      }

      &.active {
        opacity: 1;
        top: 0;
        transition: all 0.3s linear;
      }
    }
  }
  .owl-nav.disabled {
    .owl-prev, .owl-next {
      @include max1050 {
        top: 51%;
      }
    }

    .owl-next {
      @include max1050 {
        right: -60%;
      }

      @include max800 {
        right: -5%;
      }
    }
  }


  .owl-item {
    opacity: 0;
    transition: all 0.3s linear;


    &.animate {
      opacity: 1;
      transition: all 0.2s linear;

      .block {
        width: 73%;
        @include  max576 {
          width: 100%;
        }
        .title {
          opacity: 1;
          top: 0;
          transition: all 0.5s ease-in-out;
        }

        p {
          opacity: 1;
          top: 0;
          transition: all 0.5s ease-in-out;
          transition-delay: 0.3s;
        }

      }

      //.how-it-works__img {
      //  top: 0;
      //  opacity: 1;
      //  transition: all 0.3s ease-in-out;
      //  transition-delay: 0.6s;
      //}

    }

    //&.active {
    //  opacity: 1;
    //  transition: all 1s linear;
    //}
  }


  &__slide {
    width: 64%;
    padding-left: 6%;
    margin-right: 57px;
    display: flex;



    @include max800 {
      width: 100%;
      padding-left: 0;
    }
    @include max576 {
      height: 116vw;
    }

    .block {
      position: relative;
      width: 100%;
      height: 605px;

      display: none;
      //width: 55%;
      height: 100%;

      opacity: 1;
      transition: all 0.3s linear;
      margin-right: 54px;
      margin-left: 127px;

      @include max800 {
        margin-left: 94px;
        height: auto;
      }

      @include max576  {
        margin-left: 0px;
        width: 100%;
        width: 322px;
      }


      &.active {
        opacity: 1;
        transition: all 0.3s linear;
      }

      &:first-child {
        display: block;
      }

      .num {
        font-size: 200px;
        line-height: 240px;

        color: #56CCF2;
        opacity: 0.1;
        text-align: left;
        position: relative;
        left: -105px;

        @include max576 {
          font-size:130px;
          left: 0px;
          top: -37px;
        }
      }

      .format {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 6px;
        text-transform: uppercase;
        color: #F48F6F;
        position: absolute;
        top: 93px;
        left: 30px;

        @include max576 {
          font-size: 14px;
          top: 76px;
          left: 110px;
        }

        &:before {
          content: "";
          display: inline-block;
          width: 72px;
          height: 2px;
          background: #F48F6F;
          position: relative;
          top: -6px;
          left: -25px;
          @include max576 {
            width: 46px;
            margin-left: 20px;
          }

        }

      }

      .title {
        font-weight: normal;
        font-size: 40px;
        line-height: 130%;
        margin-top: -88px;
        margin-bottom: 93px;
        top: 100px;
        position: relative;
        opacity: 0;

        @include max800 {
          margin-bottom: 21px;
        }
        @include max576 {
          font-size: 30px;
        }
      }
    }

    p {
      top: 100px;
      position: relative;
      opacity: 0;
      @include max576 {
        font-size:14px;
      }
    }

  }

  &__img {
    width: 434px;
    border-radius: 49px;
    overflow: hidden;
    opacity: 1;
    //position: relative;
    //top: 20px;
    @include max1050 {
      width: 357px;
    }
    @include max800 {
      display: none;
    }
  }
}

@import "../partials/work-formats";




.theme-group__advantages {
  padding-bottom: 144px;
  position: relative;
  @include zoomMozillaContainerPosition;
  @include max576 {
    padding-bottom: 60px;
  }
}


.theme-group__advantages > .row {
  position: relative;
}

.theme-group__advantages .page__text-bg {
  width: 94px;
  height: 100%;
  top: 0;
  left: auto;
  right: -190px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 3;
  padding-top: 0;
}

.theme-group__advantages-media {
  position: relative;
  padding-top: 142px;
  padding-bottom: 175px;
}

.theme-group__advantages-media .video-preview {
  border-radius: 30px;
  overflow: hidden;
  -webkit-box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  position: relative;
  display: block;
  max-width: 550px;
  margin-left: auto;
  height: 355px;
  background: #ccc;
  @include max1050 {
    width: 367px;
    height: 208px;
  }
  @include max576 {
    margin: 0 auto;
    display: block;
    width: 89vw;
    height: 54vw;
  }
}

.theme-group__advantages-media .video-preview:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: url(../../images/blue-play.svg) no-repeat 50% 50%;

}

.theme-group__advantages-media .video-preview img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.theme-group__advantages-media::before {
  content: '';
  display: block;
  border-radius: 70px 0 0 70px;
  width: 50vw;
  height: 100%;
  position: absolute;
  left: 280px;
  top: 0;
}


.about .video-preview {
  border-radius: 45px;
  overflow: hidden;
}


.theme-group__advantages {
  position: relative;

  .container {
    display: flex;

    @include max576 {
      flex-wrap: wrap;
    }
  }

  &-rectangle {
    z-index: -1;
    position: absolute;
    top: -189px;
    @include max1050 {
      width: 64%;
      svg {
        width: 100%;
      }
    }

  }

  &-text {
    margin-left: 75px;

    p {
      font-size: 16px;
      @include max576 {
        font-size: 14px;
      }
    }

    @include max800 {
      margin-left: 0px;
    }

    .btn__outline {
      margin-top: 77px;
      margin-left: 31px;
      @include max800 {
        margin: 0;
      }
      @include max576 {
        margin: 0 auto;
        display: block;
        width: 266px;
      }
    }
  }

  &-media {
    width: 50%;
    position: relative;

    @include max576 {
      width: 100%;
    }
    .bg {
      position: absolute;
      top: 0;
      right: -254px;

      @include max800 {
        max-width: 133%;
        right: -254px;
      }
      @include max576 {
        max-width: 123%;
        right: -36px;
        top: 48px;
      }

    }

  }
}

@import "../partials/persons";

@import "../partials/press";

.press__media {
}

.mainpage-pressa {
  margin-bottom: 155px;
  margin-left: -30px;
  margin-right: -30px;
  @include max800 {
    margin-bottom: 60px;
  }
  @include max576 {
    margin-top: 60px;
  }
}

.mainpage-pressa .mainpage-group__title {
  margin-bottom: 55px;
  padding-left: 30px;
  @include max576 {
    margin-bottom: 20px;
  }
}

.mainpage-pressa .owl-carousel {

}




/**
 Блог
 */

.couchinghub-carousel-wrap {
  @include max576 {
    position: relative;
    left: -9%;
  }
}
.mainpage-blog {
  padding-top: 140px; /*overflow:hidden;*/
  position: relative;
  //height: 900px;
  overflow: hidden;
  @include zoomMozillaContainerPosition;
  @include max800 {
    padding-top: 0px;
    height: auto;
  }




  &__rectangle {
    position: absolute;
    width: 118%;
    z-index: -1;
    /* display: none; */
    top: 17px;
    left: -199px;
    @include max800 {
      left: -24px;
    }
  }

  &__text {
    .btn-info {
      margin-top: 50px;
    }

    @include max576 {
      margin-top: -8px;
      margin-bottom: 20px;
    }
  }



  .tabs-simple {
    margin-bottom: 30px;
    @include max576 {
      margin-bottom: -8px;
    }
  }

  &__posts {
    margin-left: -15px;
    padding-left: 0;
  }


}


.blog-carousel-wrap {
  @include max576 {  margin-top: 20px;}

}


@media (max-width: 767px) {
  .page_main .mainpage-blog {
    margin-top: 20px
  }

  .page_main .mainpage-blog .owl-carousel {
    padding-bottom: 25px
  }
}








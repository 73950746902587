@import "../media";

/*
 Отзывы
 */

.persons  {
  height: 824px;
  overflow: hidden;
  padding-left: 20px;
  position: relative;
  left: -20px;
  @include max1050 {
    height: 803px;
    overflow: hidden;
  }
  @include max576 {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    position: relative;
    left: unset;
    height: auto;
  }

  &__content {
    display: none;
    @include max1050 {
      width: 32%;
    }
    @include max576 {
      display: block;
      margin-top: 30px;
      width: 100%;
    }


  }

  &__rectangle {
    @include max576 {
      display: none;
    }
  }
  &__photo {
    width: 223px;
    height: 223px;
    overflow: hidden;
    border-radius: 40px;
    opacity: 0.5;
    /* -webkit-filter: grayscale(1); */
    /* filter: grayscale(1); */
    margin-top: 352px;
    @include max576 {
      width: 100%;
      opacity: 1;
      margin-top: 0px;
      height: auto;

    }
  }

  &__item {
    display: flex;
    @include max576 {
      flex-wrap: wrap;
    }

  }

  &__name {
    font-size: 30px;
    line-height: 130%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #252733;
    font-weight: bold;
    @include max576 {
      //margin-top: 20px;
    }

    span {
      display: block;
      color: #385C8B;
    }
  }


  &__specialization {
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
    color: #252733;
    margin-top: 25px;
    margin-bottom: 45px;

  }

  p {
    font-style: italic;
  }


  .owl-nav {
    margin: 0;
    position: unset;

    width: 100%;
    left: -2%;
    opacity: 0;
    top: 50%;
    transition: all 0.3s ease-out;

    @include max1050 {
      opacity: 1;
    }

    &.animate {
      transition: all 0.3s ease-out;
      top: 48%;
      opacity: 1;
    }

    .owl-prev, .owl-next {
      top: 49%;
      @include max1050 {
        top: 38%;

      }
    }

    .owl-prev {
      left: -1.5%;

    }

    .owl-next {
      right: 1.5%;
    }
  }





  .owl-item {
    opacity: 0;
    &.second {
      opacity: 1;
      transition-delay: 0.5s;
      transition: all 0.3s ease-out;
    }
    @include max1050 {
      position: relative;
      left: -228px;
      margin-top: -62px;
    }
    @include  max576{
      left: unset;
      margin-top: unset;
      opacity: 1;
      //width: inherit !important;
    }
  }

  //
  .owl-item.active {
    //width: 1099px !important;
    opacity: 1;
    transition: all 0.6s ease-out;

    @include max1050 {
      position: relative;
      left: 0px;
      margin-top: 0px;
    }
    @include max576 {
      transition: all 0s ease-out;
      //width: inherit !important;
      //margin-right: 20px;
      left: unset;
      margin-top: unset;

    }




    .persons__photo {
      width: 50%;
      height: auto;
      margin-top: 0px;
      opacity: 1;
      position: relative;
      padding-bottom: 160px;
      padding-top: 155px;
      @include max1050 {
        width: 41%;
      }
      @include max800 {
        padding-bottom: 60px;
        svg {
          width: 400px !important;
          right: -6px;
        }
      }
      @include max576 {
        width: 100%;
        padding: 0;
        svg {
          display: none;

        }
      }

      img {
        width: 434px !important;
        border-radius: 40px;
        @include max1050 {
          width: 366px !important;
        }
      }

      .persons__rectangle {
        position: absolute;
        z-index: -1;
        top: 45px;
        right: 49px;
        position: absolute;
        z-index: -1;
        top: 45px;
        right: 49px;
        @include max1050 {
          right: 27px;
        }
      }
    }

    .persons__content {
      width: 472px;
      display: block;
      //margin-left: 146px;
      margin-top: 155px;
      @include max1050 {
        width: 32%;
      }
      @include max576 {
        display: block;
        margin-top: 30px;
        width: 100%;
      }
    }



  } // owl item active


}